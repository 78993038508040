"use client";

import {
  Button,
  Input,
  Text,
  Toast,
  X,
  Y,
  t,
  tw,
  Image,
  Loader,
  Frame,
  Textarea,
} from "@reframe.so/blocks";
import { MinusCircleIcon } from "@reframe.so/icons/icons/MinusCircleIcon";
import { RotateCwIcon } from "@reframe.so/icons/icons/RotateCwIcon";
import { ExternalLinkIcon } from "@reframe.so/icons/icons/ExternalLinkIcon";
import { XCircleIcon } from "@reframe.so/icons/icons/XCircleIcon";

import { CopyButton } from "@src/components/copy-button";
import { useConfirm } from "@src/hooks/confirm";
import { useMutation, useServerAction } from "@src/hooks/mutation";
import { $uuid } from "@src/legacy/abstract/common";
import { settingsAtom, userAtom } from "@src/resync/hydrate/client";
import {
  createAPIKey,
  deleteAPIKey,
  inviteToWorkspace,
  removeDomainOfWorkspace,
  removeFromWorkspace,
  saveSecrets,
  updateWorkspace,
  updateWorkspaceWithDomain,
} from "@src/resync/mutation";
import { Provider } from "@src/utils/model/constants";
import format from "date-fns/format";
import { useRouter } from "next/navigation";

import type { VercelWorkSpaceDomainResponse } from "@src/external/vercel";
import { useState } from "react";
import { Section } from "@src/components/section";
import { XIcon } from "@reframe.so/icons/icons/XIcon";
import { UploadIcon } from "@reframe.so/icons/icons/UploadIcon";
import { Upload } from "@src/components/upload";
import { PostmarkWorkSpaceDomainEmailResponse } from "@src/external/postmark";
import { UpgradeGate } from "@src/components/upgrade";
import { LinkButton } from "@src/components/link-button";
import { NotWhiteLabel } from "@src/components/whitelabel";
import { useSession } from "next-auth/react";
import {
  nukeWorkspace as nukeWorkspaceAction,
  nukeAccount as nukeAccountAction,
} from "@src/resync/mutation/nuke";

const placeholder = "sk-xxxxxxxxxxxxxxxx";
const pineconeKeyPlaceholder = "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx";
const pineconeUrlPlaceholder = "https://retune.svc.us-east1-gcp.pinecone.io";

const A = tw("a", t``);

const KeyContainer = ({
  apiKey,
}: {
  apiKey: {
    key: string;
    createdAt: Date;
  };
}) => {
  const { key, createdAt } = apiKey;

  const [deleting, deleteKey] = useMutation(async () => {
    await deleteAPIKey(key);
  });

  if (!apiKey) {
    return null;
  }

  return (
    <X css="gap-7 cursor-pointer rounded-md justify-between items-center text-sm">
      <X css="gap-2 items-center">
        <Text css="text-neutral-500">
          {key.substring(0, 2)}...
          {key.substring(key.length - 4, key.length)}
        </Text>
        <CopyButton getText={() => key} css="text-neutral-500 w-3 h-3" />
      </X>
      <X css="gap-1 text-neutral-500">
        <Text>{format(new Date(createdAt), "MMM dd, hh:mm:ss a")}</Text>
      </X>
      <X css="cursor-pointer gap-1">
        <MinusCircleIcon onClick={deleteKey} />
      </X>
    </X>
  );
};

export const APIKeys: React.ComponentType<{
  onSuccess?: () => void;
  include?: Provider[];
  redirect?: string;
}> = ({ onSuccess, include, redirect }) => {
  const router = useRouter();

  const shouldShow = (provider: Provider) =>
    !include || include.includes(provider);

  const settings = settingsAtom.useValue();

  const [openAIKey, setOpenAIKey] = useState(
    settings.availableProviders.includes(Provider.openai) ? placeholder : ""
  );
  const [claudeKey, setClaudeKey] = useState(
    settings.availableProviders.includes(Provider.anthropic) ? placeholder : ""
  );
  const [openRouterKey, setOpenRouterKey] = useState(
    settings.availableProviders.includes(Provider.openrouter) ? placeholder : ""
  );
  const [affineKey, setAffineKey] = useState(
    settings.availableProviders.includes(Provider.affine) ? placeholder : ""
  );

  const [pineconeKey, setPineconeKey] = useState(
    settings.availableProviders.includes(Provider.pinecone)
      ? pineconeKeyPlaceholder
      : ""
  );
  const [groqKey, setGroqKey] = useState(
    settings.availableProviders.includes(Provider.groq) ? placeholder : ""
  );

  const [pineconeBaseUrl, setPineconeBaseUrl] = useState(
    settings.availableProviders.includes(Provider.pinecone)
      ? pineconeUrlPlaceholder
      : ""
  );

  const [saving, save] = useMutation(async () => {
    const result = await saveSecrets({
      openAIKey: openAIKey === placeholder ? undefined : openAIKey,
      anthropicKey: claudeKey === placeholder ? undefined : claudeKey,
      groqKey: groqKey === placeholder ? undefined : groqKey,
      openRouterKey: openRouterKey === placeholder ? undefined : openRouterKey,
      pineconeKey:
        pineconeKey === pineconeKeyPlaceholder ? undefined : pineconeKey,
      affineKey: affineKey === placeholder ? undefined : affineKey,
      pineconeBaseUrl:
        pineconeBaseUrl === pineconeUrlPlaceholder
          ? undefined
          : pineconeBaseUrl,
    });

    if (!result.success) {
      throw new Error(result.error.message);
    }

    Toast.create({
      title: "Saved successfully!",
      variant: "success",
    });

    onSuccess?.();

    if (redirect) {
      window.location.href = "/" + redirect;
    }
  });

  return (
    <Y css="gap-7">
      {!shouldShow(Provider.openai) ? null : (
        <Y css="gap-2">
          <Y css="gap-1">
            <Text css="text-sm font-medium">OpenAI API Key</Text>
            <Text css="text-xs text-neutral-600">
              Click{" "}
              <A
                target="_blank"
                href="https://beta.openai.com/account/api-keys"
                rel="noreferrer"
                css="underline text-primary-400"
              >
                here
              </A>{" "}
              to get your API key.
            </Text>
          </Y>

          <Y css="gap-2">
            <Input
              value={openAIKey}
              onChange={(e) => setOpenAIKey(e.target.value)}
              size={14}
              css="outline-1 min-w-full p-3"
              placeholder={placeholder}
            />
          </Y>
        </Y>
      )}

      {!shouldShow(Provider.openrouter) ? null : (
        <Y css="gap-2">
          <Y css="gap-1">
            <Text css="text-sm font-medium">OpenRouter API Key</Text>
            <Text css="text-xs text-neutral-600">
              Click{" "}
              <A
                target="_blank"
                href="https://openrouter.ai"
                rel="noreferrer"
                css="underline text-primary-400"
              >
                here
              </A>{" "}
              to get your API key.
            </Text>
          </Y>

          <Y css="gap-2">
            <Input
              value={openRouterKey}
              onChange={(e) => setOpenRouterKey(e.target.value)}
              size={14}
              css="outline-1 min-w-full p-3"
              placeholder={placeholder}
            />
          </Y>
        </Y>
      )}

      {!shouldShow(Provider.anthropic) ? null : (
        <Y css="gap-2">
          <Y css="gap-1">
            <Text css="text-sm font-medium">Claude API Key</Text>
            <Text css="text-xs text-neutral-600">
              Click{" "}
              <A
                target="_blank"
                href="https://console.anthropic.com/docs/access"
                rel="noreferrer"
                css="underline text-primary-400"
              >
                here
              </A>{" "}
              to get your API key.
            </Text>
          </Y>

          <Y css="gap-2">
            <Input
              value={claudeKey}
              onChange={(e) => setClaudeKey(e.target.value)}
              size={14}
              css="outline-1 min-w-full p-3"
              placeholder={placeholder}
            />
          </Y>
        </Y>
      )}
      {!shouldShow(Provider.affine) ? null : (
        <Y css="gap-2">
          <Y css="gap-1">
            <Text css="text-sm font-medium">AlpineX API Key</Text>
            <Text css="text-xs text-neutral-600">
              Click{" "}
              <A
                target="_blank"
                href="https://alpinex.ai/"
                rel="noreferrer"
                css="underline text-primary-400"
              >
                here
              </A>{" "}
              to get your API key.
            </Text>
          </Y>

          <Y css="gap-2">
            <Input
              value={affineKey}
              onChange={(e) => setAffineKey(e.target.value)}
              size={14}
              css="outline-1 min-w-full p-3"
              placeholder={placeholder}
            />
          </Y>
        </Y>
      )}

      {!shouldShow(Provider.groq) ? null : (
        <Y css="gap-2">
          <Y css="gap-1">
            <Text css="text-sm font-medium">Groq API Key</Text>
            <Text css="text-xs text-neutral-600">
              Click{" "}
              <A
                target="_blank"
                href="https://console.groq.com/keys"
                rel="noreferrer"
                css="underline text-primary-400"
              >
                here
              </A>{" "}
              to get your API key.
            </Text>
          </Y>

          <Y css="gap-2">
            <Input
              value={groqKey}
              onChange={(e) => setGroqKey(e.target.value)}
              size={14}
              css="outline-1 min-w-full p-3"
              placeholder={placeholder}
            />
          </Y>
        </Y>
      )}

      {!shouldShow(Provider.pinecone) ? null : (
        <>
          <Y css="gap-2">
            <Y css="gap-1">
              <Text css="text-sm font-medium">Pinecone Base URL</Text>
              <Text css="text-xs text-neutral-600">
                Bringing your own pinecone is supported on new workspaces only.
                If you want to update your base URL, please email
                support+pinecone@retune.so.
              </Text>
            </Y>

            <Y css="gap-2">
              <Input
                value={pineconeBaseUrl}
                onChange={(e) => setPineconeBaseUrl(e.target.value)}
                size={14}
                css="outline-1 min-w-full p-3"
                disabled={true}
              />
            </Y>
          </Y>

          <Y css="gap-2">
            <Y css="gap-1">
              <Text css="text-sm font-medium">Pinecone API Key</Text>
              <Text css="text-xs text-neutral-600">
                Click{" "}
                <A
                  target="_blank"
                  href="https://app.pinecone.io/"
                  rel="noreferrer"
                  css="underline text-primary-400"
                >
                  here
                </A>{" "}
                to get your Pinecone API key.
              </Text>
            </Y>

            <Y css="gap-2">
              <Input
                value={pineconeKey}
                onChange={(e) => setPineconeKey(e.target.value)}
                size={14}
                css="outline-1 min-w-full p-3"
                placeholder={pineconeKeyPlaceholder}
                disabled={pineconeBaseUrl === ""}
              />
            </Y>
          </Y>
        </>
      )}

      <X css="gap-2">
        <Button
          loading={saving}
          onClick={save}
          disabled={
            (!shouldShow(Provider.openai)
              ? true
              : openAIKey === placeholder || openAIKey.length === 0) &&
            (!shouldShow(Provider.anthropic)
              ? true
              : claudeKey === placeholder || claudeKey.length === 0) &&
            (!shouldShow(Provider.openrouter)
              ? true
              : openRouterKey === placeholder || openRouterKey.length === 0) &&
            (!shouldShow(Provider.groq)
              ? true
              : groqKey === placeholder || groqKey.length === 0) &&
            (!shouldShow(Provider.affine)
              ? true
              : affineKey === placeholder || affineKey.length === 0)
          }
        >
          Save
        </Button>
      </X>
    </Y>
  );
};

const WorkspaceDetails = () => {
  const settings = settingsAtom.useValue();
  const imageFileTypes = ["image/jpeg", "image/png"];

  const [handle, setHandle] = useState(settings.workspace.slug);
  const [name, setName] = useState(settings.workspace.name);
  const [logo, setLogo] = useState(settings.workspace.logo ?? "");

  const [saving, save] = useServerAction(
    (updates: { name?: string; logo?: string; handle?: string }) =>
      updateWorkspace(updates)
  );

  return (
    <Y css="gap-8">
      <Y css="gap-2 justify grow">
        <Text css="text-sm font-medium">Slug</Text>
        <X css="gap-1">
          <Input
            css="p-3 w-full"
            value={handle}
            onChange={(e) =>
              setHandle(e.target.value.replace(/[^a-z0-9.]/g, ""))
            }
            size={20}
          />
        </X>
      </Y>

      <Y css="gap-2 justify grow">
        <Text css="text-sm font-medium">Name</Text>
        <X css="gap-1">
          <Input
            css="p-3 w-full"
            value={name}
            onChange={(e) => setName(e.target.value)}
            size={20}
          />
        </X>
      </Y>
      <Y css="gap-2 grow justify">
        <Text css="text-sm font-medium">Logo (square)</Text>
        <Upload
          prefix={settings.workspace.id}
          fileTypes={imageFileTypes}
          onUpload={(url) => {
            setLogo(url);
            save({ logo: url });
          }}
          render={({ uploading, unsupported, onClick }) => (
            <>
              <X css="items-center gap-4">
                {uploading ? (
                  <X>
                    <Loader size="xs" css="mx-auto" />
                  </X>
                ) : (
                  logo && (
                    <Frame css="rounded-full">
                      <Frame.Layer css=" z-50 inset-0 opacity-0 hover:opacity-90 bg-neutral-700 items-center justify-center cursor-pointer ">
                        <XIcon
                          color=" white"
                          css=" w-6 h-6"
                          onClick={() => {
                            setLogo("");
                          }}
                        />
                      </Frame.Layer>
                      <Image
                        alt="avatar_src"
                        src={logo}
                        fit="cover"
                        css="w-8 h-8"
                      />
                    </Frame>
                  )
                )}

                <Y>
                  <Y css="cursor-pointer h-8 justify-center " onClick={onClick}>
                    <UploadIcon />
                  </Y>
                </Y>
              </X>
              {unsupported && (
                <Text color={"$red9"} css="text-xs text-red-600">
                  File type not supported
                </Text>
              )}
            </>
          )}
        />
      </Y>
      <X css="w-20 shrink-0">
        <Button
          variant="secondary"
          disabled={name.length === 0 || saving}
          loading={saving}
          onClick={() => save({ name, logo, handle })}
        >
          Save
        </Button>
      </X>
    </Y>
  );
};

const DnsEntry = ({
  type,
  name,
  value,
  verified,
}: {
  type: string;
  name: string;
  value: string;
  verified?: boolean;
}) => {
  return (
    <Y css="hug gap-4 shrink-0 border-neutral-200/80 border rounded-lg p-3 bg-neutral-50/60">
      <X css="gap-2 hug items-center">
        <Y css="gap-2 basis-[48px] shrink-0 grow-0">
          <Text css="text-xs">Type</Text>
        </Y>

        <Textarea
          css="grow font-mono font-thin text-neutral-600 border-neutral-400/20"
          rows={1}
          autoHeight
          outline={false}
          value={type}
          readOnly
        ></Textarea>
        {verified ? (
          <Button className="cursor-default bg-green-50 text-xs text-green-800">
            {" "}
            verified
          </Button>
        ) : (
          <Button className="cursor-default bg-red-50 text-xs text-red-800">
            {" "}
            unverified
          </Button>
        )}
      </X>

      <X css="gap-2 hug items-center">
        <Text css="text-xs basis-[48px] shrink-0 grow-0">Name</Text>
        <Textarea
          css="grow font-mono font-thin text-neutral-600 border-neutral-400/20"
          rows={1}
          autoHeight
          outline={false}
          value={name}
          readOnly
        ></Textarea>
      </X>

      <X css="gap-2 hug items-center">
        <Text css="text-xs basis-[48px] shrink-0 grow-0">Value</Text>
        <Textarea
          css="grow font-mono font-thin text-neutral-600 border-neutral-400/20"
          rows={1}
          autoHeight
          outline={false}
          value={value}
          readOnly
        ></Textarea>
      </X>
    </Y>
  );
};

const CustomDomainStatus: React.ComponentType<{
  onRemove: () => void;
  domainStatus: VercelWorkSpaceDomainResponse;
  emailStatus: PostmarkWorkSpaceDomainEmailResponse;
}> = ({ onRemove, domainStatus, emailStatus }) => {
  const settings = settingsAtom.useValue();
  const isDomainConfigured = settings.workspace.domainVerifiedAt !== null;
  const domain = settings.workspace.domain ?? "";
  const router = useRouter();

  const email = settings.workspace.email ?? "";
  const isEmailConfigured = settings.workspace.emailDomainVerifiedAt !== null;

  return (
    <Y css="border p-4 gap-y-8 rounded-md">
      <Y css="gap-2">
        <Y>
          <X css="justify-between">
            <Text css="font-bold">{settings.workspace.domain}</Text>
            <X css="justify-center items-center wrap">
              {isDomainConfigured ? (
                <LinkButton
                  external
                  href={`https://${domain}`}
                  variant="link"
                  css="p-0"
                >
                  <ExternalLinkIcon css="shrink-0 rounded-full hover:outline-1 h-6 w-6 p-1 text-neutral-600 hover:bg-red-50 hover:text-primary-900" />
                </LinkButton>
              ) : null}
              <Button variant="link" onClick={() => router.refresh()} css="p-0">
                <RotateCwIcon css="shrink-0 rounded-full hover:outline-1 h-6 w-6 p-1 text-neutral-600 hover:bg-red-50 hover:text-primary-900" />
              </Button>
              <Button
                variant="link"
                onClick={() => onRemove()}
                css="shrink-0 rounded-full hover:outline-1 h-7 w-7 p-1 text-neutral-600 hover:bg-red-50 hover:text-primary-900"
              >
                <XCircleIcon css="h-4 w-4" />
              </Button>
            </X>
          </X>
          {!isDomainConfigured ? (
            <X>
              <Text css="font-semibold text-orange-500 text-xs">
                Domain is pending verification.
              </Text>
            </X>
          ) : (
            <X>
              <Text css="font-semibold text-blue-500 text-xs">
                Configuration is valid.
              </Text>
            </X>
          )}
        </Y>
        <Y css="border-t gap-0">
          {!isDomainConfigured ? (
            !domainStatus?.verified && domainStatus?.dns.type === "TXT" ? (
              <Y css="gap-2">
                <Text css="font-medium text-xs mt-4">
                  Verify Domain Ownership
                  <X css="border-b w-2/5 mt-1.5"></X>
                </Text>
                <Text css="font-medium text-xs mt-0">
                  {`Another Vercel account is using this domain. Please set the
                following TXT record on ${domainStatus.dns.name} to use ${domain} in
                this project. If you wish to `}
                  <a
                    className="text-blue-800 underline"
                    target="_blank"
                    href="https://vercel.com/guides/how-can-i-move-a-domain-to-a-team#move-button"
                  >
                    transfer
                  </a>
                  {` the entire domain, this will need to be done by the current
                domain owner. Once the verification is completed and the domain
                is successfully configured, the TXT record can be removed.`}
                </Text>
              </Y>
            ) : (
              <Text css="font-medium text-xs mt-2">
                Set the following record on your DNS provider to continue:
              </Text>
            )
          ) : null}
          {!!domainStatus?.dns ? (
            <DnsEntry {...domainStatus.dns} verified={isDomainConfigured} />
          ) : null}
        </Y>
      </Y>
      <Y css="gap-2">
        <Y css="gap-2">
          <X css="justify-between">
            <Text css="font-bold">{email}</Text>
          </X>
          {!isEmailConfigured ? (
            <X>
              <Text css="font-semibold text-orange-500 text-xs">
                Email is pending verification.
              </Text>
            </X>
          ) : (
            <X>
              <Text css="font-semibold text-blue-500 text-xs">
                Configuration is valid.
              </Text>
            </X>
          )}
        </Y>

        <Y css="border-t gap-8">
          {emailStatus?.dnsDkim.verified && !emailStatus?.dnsCname.verified ? (
            <Text css="text-xs mt-2">
              Your configuration is valid, but the CNAME record can take up to
              48 hours to propagate. In the meantime,{" "}
              <strong>
                you can still send emails from your custom domain.
              </strong>
            </Text>
          ) : (
            <Text css="font-medium text-xs mt-2">
              Set the following records on your DNS provider to continue:
            </Text>
          )}

          {emailStatus?.dnsDkim ? <DnsEntry {...emailStatus.dnsDkim} /> : null}

          {emailStatus?.dnsCname ? (
            <DnsEntry {...emailStatus.dnsCname} />
          ) : null}
        </Y>
      </Y>
    </Y>
  );
};

export const CustomDomain: React.ComponentType<{
  domainStatus: VercelWorkSpaceDomainResponse;
  emailStatus: PostmarkWorkSpaceDomainEmailResponse;
}> = ({ domainStatus, emailStatus }) => {
  const settings = settingsAtom.useValue();
  const [domain, setDomain] = useState(settings.workspace.domain ?? "");
  const { confirm, Confirm } = useConfirm();

  const [saving, save] = useServerAction(
    (domain: string) =>
      updateWorkspaceWithDomain({
        domain,
      }),
    {
      onSuccess: () => {
        Toast.create({
          title: `Successfully saved!`,
          variant: "success",
        });
      },
    }
  );

  const [removing, remove] = useServerAction(
    (domain: string) =>
      removeDomainOfWorkspace({
        domain,
      }),
    {
      onSuccess: () => {
        Toast.create({
          title: `Successfully removed!`,
          variant: "success",
        });
      },
    }
  );

  return (
    <UpgradeGate feature="custom-domain" css="gap-2 justify-center grow">
      <Text css="text-sm font-medium">Domain Name</Text>
      <Text css="text-xs text-neutral-600">
        Instead of using your main domain (eg: <strong>yourdomain.com</strong>),
        We strongly recommend using a subdomain (eg:{" "}
        <strong>ai.yourdomain.com</strong> or{" "}
        <strong>chat.yourdomain.com</strong>)
      </Text>

      <X css="gap-1">
        <Input
          css="p-3 w-full"
          value={domain}
          placeholder="xyz.example.com"
          onChange={(e) => setDomain(e.target.value)}
          size={20}
        />
        <Button
          css="w-20 shrink-0"
          variant="secondary"
          disabled={
            !domain.match(/^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,10}$/)
          }
          loading={saving}
          onClick={() => save(domain.toLowerCase())}
        >
          Save
        </Button>
      </X>
      {settings.workspace.domain ? (
        <CustomDomainStatus
          onRemove={() => {
            confirm({
              action: "Delete",
              onConfirm: async () => remove(settings.workspace.domain ?? ""),
            });
          }}
          domainStatus={domainStatus}
          emailStatus={emailStatus}
        />
      ) : null}
      <Confirm />
    </UpgradeGate>
  );
};

export const Settings: React.ComponentType<{
  customDomain: React.ReactNode;
}> = ({ customDomain }) => {
  const settings = settingsAtom.useValue();

  const { data: session } = useSession();

  const [creatingKey, createKey] = useMutation(async () => {
    await createAPIKey($uuid());
  });

  const [inviting, invite] = useMutation(async (email: string) => {
    const result = await inviteToWorkspace({
      email,
      role:
        session?.user.admin || session?.user.adminEmail ? "creator" : "member",
    });

    if (!result.success) {
      throw new Error(result.error.message);
    }

    Toast.create({
      title: `Invited ${email} to workspace!`,
      variant: "success",
    });
  });

  const [removing, removeMember] = useMutation(async (email: string) => {
    const result = await removeFromWorkspace({
      email,
    });

    if (!result.success) {
      throw new Error(result.error.message);
    }

    Toast.create({
      title: `Removed ${email} from workspace!`,
      variant: "success",
    });
  });

  const [inviteEmail, setInviteEmail] = useState("");
  const { confirm, Confirm } = useConfirm();
  const currentUser = userAtom.useValue();

  const router = useRouter();

  const [nukingWorkspace, nukeWorkspace] = useServerAction(
    nukeWorkspaceAction,
    {
      onSuccess: () => {
        router.push(`/workspace/${session!.user.id}`);
      },
    }
  );

  const [nukingAccount, nukeAccount] = useServerAction(nukeAccountAction, {
    onSuccess: () => {
      router.push("/sign-out?reason=nuke");
    },
  });

  const myWorkspace = settings.workspace.id === settings.workspace.userId;

  const enableCollab = settings.workspace.members.length >= 1;

  return (
    <Y css="max-w-4xl gap-16">
      <Confirm />
      <Section
        title="External API Keys"
        subtitle="API keys are used to access external services like OpenAI, OpenRouter and Claude."
      >
        {<APIKeys />}
      </Section>
      <Section
        title="Workspace"
        subtitle="Manage your workspace and invite collaborators."
      >
        <WorkspaceDetails />
        <>
          <Y css="gap-2 justify-center grow">
            <Text css="text-sm font-medium">Members</Text>
            {[
              {
                user: settings.workspace.user,
                role: "owner",
              },
              ...settings.workspace.members,
            ].map(({ user, role }) => (
              <X key={user.id} css="justify-between gap-x-6 py-5">
                <X css="min-w-0 gap-x-4">
                  {user.image ? (
                    <Image
                      css="h-12 w-12 flex-none rounded-full bg-neutral-50"
                      src={user.image}
                      alt={user.name ?? ""}
                    />
                  ) : (
                    <X css="w-12 h-12 bg-neutral-700 text-neutral-50 rounded-full items-center justify-center text-2xl">
                      <Text>{user.name?.[0] ?? ""}</Text>
                    </X>
                  )}

                  <Y css="min-w-0 flex-auto">
                    <Text css="text-sm font-medium leading-6 text-neutral-800">
                      {user.name ?? "Unknown"}
                    </Text>
                    <Text css="mt-1 truncate text-xs leading-5 text-neutral-500">
                      {user.email}
                    </Text>
                  </Y>
                </X>
                <Y css="shrink-0 items-end justify-center">
                  <Button
                    css="h-5 shrink-0 text-sm font-medium leading-6 text-neutral-800 capitalize cursor-default w-20 hover:no-underline p-0"
                    variant="link"
                  >
                    {role}
                  </Button>
                  {role === "owner" ||
                  user.email === currentUser.email ? null : (
                    <Button
                      css="h-5 shrink-0 mt-1 text-xs leading-5 text-orange-500 w-20 p-0"
                      variant="link"
                      onClick={() => {
                        confirm({
                          onConfirm: () => removeMember(user.email),
                          action: "Confirm",
                        });
                      }}
                    >
                      remove
                    </Button>
                  )}
                </Y>
              </X>
            ))}
          </Y>
          <X css="gap-1">
            {!enableCollab ||
            session?.user.admin ||
            session?.user.adminEmail ? (
              <>
                <Input
                  placeholder="Email of collaborator"
                  value={inviteEmail}
                  onChange={(e) => setInviteEmail(e.target.value.toLowerCase())}
                  size={20}
                  css="p-3 w-full"
                />
                <Button
                  css="w-20 shrink-0"
                  variant="secondary"
                  disabled={
                    !inviteEmail.match(
                      /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/g
                    ) ||
                    inviteEmail === settings.workspace.user.email ||
                    settings.workspace.members.some(
                      ({ user }) => user.email === inviteEmail
                    ) ||
                    inviting
                  }
                  onClick={() => invite(inviteEmail)}
                >
                  Invite
                </Button>
              </>
            ) : null}
          </X>
        </>
      </Section>

      {settings.availableProviders.length > 0 && (
        <Section
          title={
            <>
              <NotWhiteLabel>Re:tune</NotWhiteLabel> API Keys
            </>
          }
          subtitle={
            <>
              <NotWhiteLabel>Re:tune</NotWhiteLabel> API keys are used to access
              the <NotWhiteLabel>Re:tune</NotWhiteLabel> API and other advanced
              features.
            </>
          }
        >
          <Y css="gap-6">
            <Y css="gap-2">
              {settings.apiKeys.map((apiKey, index) => (
                <KeyContainer key={index} apiKey={apiKey} />
              ))}
            </Y>
            <X>
              <Button
                variant="secondary"
                loading={creatingKey}
                onClick={createKey}
              >
                + Create new
              </Button>
            </X>
          </Y>
        </Section>
      )}

      <NotWhiteLabel>
        <Section
          title="Custom Domain"
          subtitle="Setup your custom domain form here"
        >
          {customDomain}
        </Section>
      </NotWhiteLabel>

      <Section title="Danger Zone" subtitle="Delete your workspace and data">
        <Y css="gap-2 justify-center grow">
          <Text css="text-sm font-medium">Delete Workspace</Text>
          <Text css="text-xs text-neutral-600">
            {myWorkspace
              ? "Primary workspace can not be deleted without deleting account."
              : "This will permanently delete this workspace and all associated data."}
          </Text>

          <Button
            variant="destructive"
            disabled={
              nukingWorkspace ||
              settings.workspace.id === settings.workspace.userId
            }
            loading={nukingWorkspace}
            onClick={() => {
              confirm({
                action: "Delete",
                dangerous: 7,
                onConfirm: async () => {
                  nukeWorkspace(settings.workspace.id);
                },
              });
            }}
          >
            Delete Workspace
          </Button>
        </Y>
        {myWorkspace && (
          <Y css="gap-2 justify-center grow">
            <Text css="text-sm font-medium">Delete Account</Text>
            <Text css="text-xs text-neutral-600">
              This will permanently delete your account and all associated data.
            </Text>

            <Button
              variant="destructive"
              loading={nukingAccount}
              onClick={() => {
                confirm({
                  action: "Delete",
                  dangerous: 16,
                  onConfirm: async () => {
                    console.log("delete account");
                    nukeAccount();
                  },
                });
              }}
            >
              Delete Account
            </Button>
          </Y>
        )}
      </Section>
    </Y>
  );
};
